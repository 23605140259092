import React from 'react';
import clsx from 'clsx';

import * as styles from './Divider.module.css';

export const HDivider = ({className, size = 'sm'}) => {

  const classes = clsx(
    styles.hdivider,
    styles[size],
    className
  );

  return (
    <div className={classes}></div>
  )
}

export const VDivider = ({className, size = 'sm'}) => {

  const classes = clsx(
    styles.vdivider,
    styles[size],
    className
  );

  return (
    <div className={classes}></div>
  )
}

export const DashDivider = ({className}) => {
  const classes = clsx(
    styles.dashDivider,
    className
  );

  return (
    <hr className={classes} />
  );
}

export const SectionDividerWithLabel = ({className, color = 'purple-light', children}) => {
  const colorAsCamel = color.replace(/-./g, x=>x[1].toUpperCase());
  const classes = clsx(
    styles.sectionDivider,
    styles[colorAsCamel],
    className
  );

  return (
    <div className={classes}>
      <div className={styles.sectionDividerLabel}>
        {children}
      </div>
    </div>
  )
}

