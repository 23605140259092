// extracted by mini-css-extract-plugin
export var dashDivider = "Divider-module--dashDivider--e6dd5";
export var hdivider = "Divider-module--hdivider--d46ce";
export var lg = "Divider-module--lg--09632";
export var md = "Divider-module--md--f8c84";
export var purpleLight = "Divider-module--purple-light--03653";
export var sectionDivider = "Divider-module--section-divider--b26f0";
export var sectionDividerLabel = "Divider-module--section-divider-label--ddf81";
export var sm = "Divider-module--sm--0d2ca";
export var vdivider = "Divider-module--vdivider--eae0d";
export var xl = "Divider-module--xl--1de4c";