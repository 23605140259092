exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-capabilities-data-analytics-and-evidence-generation-js": () => import("./../../../src/pages/capabilities/data-analytics-and-evidence-generation.js" /* webpackChunkName: "component---src-pages-capabilities-data-analytics-and-evidence-generation-js" */),
  "component---src-pages-capabilities-index-js": () => import("./../../../src/pages/capabilities/index.js" /* webpackChunkName: "component---src-pages-capabilities-index-js" */),
  "component---src-pages-capabilities-modeling-and-value-demonstration-js": () => import("./../../../src/pages/capabilities/modeling-and-value-demonstration.js" /* webpackChunkName: "component---src-pages-capabilities-modeling-and-value-demonstration-js" */),
  "component---src-pages-capabilities-value-and-access-strategy-js": () => import("./../../../src/pages/capabilities/value-and-access-strategy.js" /* webpackChunkName: "component---src-pages-capabilities-value-and-access-strategy-js" */),
  "component---src-pages-careers-index-js": () => import("./../../../src/pages/careers/index.js" /* webpackChunkName: "component---src-pages-careers-index-js" */),
  "component---src-pages-case-studies-access-strategy-case-study-on-pricing-js": () => import("./../../../src/pages/case-studies/access-strategy-case-study-on-pricing.js" /* webpackChunkName: "component---src-pages-case-studies-access-strategy-case-study-on-pricing-js" */),
  "component---src-pages-case-studies-access-strategy-heor-roadmap-js": () => import("./../../../src/pages/case-studies/access-strategy-heor-roadmap.js" /* webpackChunkName: "component---src-pages-case-studies-access-strategy-heor-roadmap-js" */),
  "component---src-pages-case-studies-data-analytics-medicare-case-study-js": () => import("./../../../src/pages/case-studies/data-analytics-medicare-case-study.js" /* webpackChunkName: "component---src-pages-case-studies-data-analytics-medicare-case-study-js" */),
  "component---src-pages-case-studies-demonstrating-value-of-breakthrough-technology-js": () => import("./../../../src/pages/case-studies/demonstrating-value-of-breakthrough-technology.js" /* webpackChunkName: "component---src-pages-case-studies-demonstrating-value-of-breakthrough-technology-js" */),
  "component---src-pages-case-studies-estimating-the-obesity-attributable-risk-of-cardiovascular-disease-js": () => import("./../../../src/pages/case-studies/estimating-the-obesity-attributable-risk-of-cardiovascular-disease.js" /* webpackChunkName: "component---src-pages-case-studies-estimating-the-obesity-attributable-risk-of-cardiovascular-disease-js" */),
  "component---src-pages-case-studies-fda-regulatory-support-via-quantitative-benefit-risk-analysis-js": () => import("./../../../src/pages/case-studies/fda-regulatory-support-via-quantitative-benefit-risk-analysis.js" /* webpackChunkName: "component---src-pages-case-studies-fda-regulatory-support-via-quantitative-benefit-risk-analysis-js" */),
  "component---src-pages-case-studies-hospital-transparency-data-js": () => import("./../../../src/pages/case-studies/hospital-transparency-data.js" /* webpackChunkName: "component---src-pages-case-studies-hospital-transparency-data-js" */),
  "component---src-pages-case-studies-household-non-medical-costs-to-accommodate-duchenne-muscular-dystrophy-js": () => import("./../../../src/pages/case-studies/household-non-medical-costs-to-accommodate-duchenne-muscular-dystrophy.js" /* webpackChunkName: "component---src-pages-case-studies-household-non-medical-costs-to-accommodate-duchenne-muscular-dystrophy-js" */),
  "component---src-pages-case-studies-improving-the-modeling-of-alzheimers-disease-progression-js": () => import("./../../../src/pages/case-studies/improving-the-modeling-of-alzheimers-disease-progression.js" /* webpackChunkName: "component---src-pages-case-studies-improving-the-modeling-of-alzheimers-disease-progression-js" */),
  "component---src-pages-case-studies-index-js": () => import("./../../../src/pages/case-studies/index.js" /* webpackChunkName: "component---src-pages-case-studies-index-js" */),
  "component---src-pages-case-studies-medicare-insights-into-patients-with-mcrpc-js": () => import("./../../../src/pages/case-studies/medicare-insights-into-patients-with-mcrpc.js" /* webpackChunkName: "component---src-pages-case-studies-medicare-insights-into-patients-with-mcrpc-js" */),
  "component---src-pages-case-studies-modeling-user-friendly-tool-case-study-js": () => import("./../../../src/pages/case-studies/modeling-user-friendly-tool-case-study.js" /* webpackChunkName: "component---src-pages-case-studies-modeling-user-friendly-tool-case-study-js" */),
  "component---src-pages-case-studies-non-alcoholic-steatohepatitis-as-a-risk-factor-in-hepatocellular-carcinoma-development-js": () => import("./../../../src/pages/case-studies/non-alcoholic-steatohepatitis-as-a-risk-factor-in-hepatocellular-carcinoma-development.js" /* webpackChunkName: "component---src-pages-case-studies-non-alcoholic-steatohepatitis-as-a-risk-factor-in-hepatocellular-carcinoma-development-js" */),
  "component---src-pages-case-studies-patient-support-program-modeling-js": () => import("./../../../src/pages/case-studies/patient-support-program-modeling.js" /* webpackChunkName: "component---src-pages-case-studies-patient-support-program-modeling-js" */),
  "component---src-pages-case-studies-patient-support-programs-case-study-js": () => import("./../../../src/pages/case-studies/patient-support-programs-case-study.js" /* webpackChunkName: "component---src-pages-case-studies-patient-support-programs-case-study-js" */),
  "component---src-pages-case-studies-prevalence-and-overlap-of-cardiac-renal-and-metabolic-conditions-in-us-adults-js": () => import("./../../../src/pages/case-studies/prevalence-and-overlap-of-cardiac-renal-and-metabolic-conditions-in-us-adults.js" /* webpackChunkName: "component---src-pages-case-studies-prevalence-and-overlap-of-cardiac-renal-and-metabolic-conditions-in-us-adults-js" */),
  "component---src-pages-case-studies-taking-the-pulse-of-industry-trends-and-policies-js": () => import("./../../../src/pages/case-studies/taking-the-pulse-of-industry-trends-and-policies.js" /* webpackChunkName: "component---src-pages-case-studies-taking-the-pulse-of-industry-trends-and-policies-js" */),
  "component---src-pages-case-studies-testing-predictions-vs-actuals-for-drug-spending-js": () => import("./../../../src/pages/case-studies/testing-predictions-vs-actuals-for-drug-spending.js" /* webpackChunkName: "component---src-pages-case-studies-testing-predictions-vs-actuals-for-drug-spending-js" */),
  "component---src-pages-case-studies-understanding-patient-outcomes-in-co-pay-accumulator-adjustment-programs-js": () => import("./../../../src/pages/case-studies/understanding-patient-outcomes-in-co-pay-accumulator-adjustment-programs.js" /* webpackChunkName: "component---src-pages-case-studies-understanding-patient-outcomes-in-co-pay-accumulator-adjustment-programs-js" */),
  "component---src-pages-case-studies-us-hcv-high-risk-policy-model-js": () => import("./../../../src/pages/case-studies/us-hcv-high-risk-policy-model.js" /* webpackChunkName: "component---src-pages-case-studies-us-hcv-high-risk-policy-model-js" */),
  "component---src-pages-case-studies-us-mdd-systems-model-value-message-js": () => import("./../../../src/pages/case-studies/us-mdd-systems-model-value-message.js" /* webpackChunkName: "component---src-pages-case-studies-us-mdd-systems-model-value-message-js" */),
  "component---src-pages-case-studies-us-tvcea-model-oncology-js": () => import("./../../../src/pages/case-studies/us-tvcea-model-oncology.js" /* webpackChunkName: "component---src-pages-case-studies-us-tvcea-model-oncology-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-medicare-index-js": () => import("./../../../src/pages/medicare/index.js" /* webpackChunkName: "component---src-pages-medicare-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-our-story-index-js": () => import("./../../../src/pages/our-story/index.js" /* webpackChunkName: "component---src-pages-our-story-index-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */)
}

